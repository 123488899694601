import React from 'react';

/* Styles */
import { useSelector } from 'react-redux';
import styles from './Navigation.module.less';

/* Components */
import { userPermissions } from '../../../../Constants/User';
import NavigationLink, {
  AdminNavigationDropdown,
  AdminNavigationLink,
} from './NavigationLink/NavigationLink';
import ContactsLinkHash from './NavigationLink/ContactsLinkHash';
import ExternalNavigationLink from './NavigationLink/ExternalNavigationLink';
import NavigationCollapse from './NavigationLink/NavigationCollapse';
import NavigationDropdown from './NavigationLink/NavigationDropdown';
import { UserData } from '../../../../interfaces/Data/responceDataTypes';
import { configurationSelector } from '../../../../store/common/selectors';
import routes from "../../../../references/routes";

/**
 * Navigation component props types definition
 */
export type NavigationPropsTypes = {
  /** User data object */
  user: UserData | null,
  /** Render mobile navigation */
  mobile?: boolean,
  /** Router history */
  history?: any,
  /** Adds inner */
  inner?: boolean,
  /** Has no footer */
  noFooter?: boolean
};

type AdminProps = {
  adminMenuLinks: string[][]
};

export const contactsList = (
  <div className={styles.contactsList}>
    <NavigationLink contactLink link="/about/" label="About us"/>
    <NavigationLink contactLink link="/terms-of-service/" label="Terms of Service"/>
    <NavigationLink contactLink link="/team/" label="Team"/>
    <ExternalNavigationLink link="https://info.insidetracker.com/careers/" label="Careers"/>
    <ExternalNavigationLink
      link="https://info.insidetracker.com/professionals"
      label="InsideTracker for Professionals"
    />
    <NavigationLink contactLink link="/press-page/" label="In the press"/>
    <ExternalNavigationLink link="https://info.insidetracker.com/security" label="Security"/>
    <NavigationLink contactLink link="/policy-privacy/" label="Privacy Policy"/>
    <NavigationLink contactLink link="/research-consent/" label="DNA Research Consent"/>
    <NavigationLink contactLink link="/responsible-disclosure-policy/" label="Responsible Disclosure Policy"/>
    <NavigationLink contactLink link="/product-consent/" label="Product Consent"/>
    <ContactsLinkHash link="/#scrollingSection" label="How it works"/>
  </div>
);

export const bottomContactsList = (
  <div className={styles.contactsList}>
    <ExternalNavigationLink
      contactLink
      link="//insidetracker-faq.gorgias.help/en-US/is-insidetracker-hipaa-compliant-288846"
      label="HIPAA"
    />
    <ExternalNavigationLink
      contactLink
      link="//insidetracker-faq.gorgias.help/en-US/"
      label="FAQ"
    />
    <ExternalNavigationLink
      contactLink
      link="//insidetracker-faq.gorgias.help/en-US/how-can-i-contact-you-288947"
      label="Contact us"
    />
  </div>
);

export const CommonNavBarList = () => {
  const configuration = useSelector(configurationSelector);
  return (
    <div className={styles.navbarList}>
      <ExternalNavigationLink
        link={configuration.shopifyUrl}
        label="Plans"
        inNewTab={false}
      />
      <NavigationLink link="/tour/" label="Tour"/>
      <NavigationLink link="/science/" label="Science"/>
      <ExternalNavigationLink link="//blog.insidetracker.com/" label="InsideGuide"/>
      <NavigationLink link="/testimonial/" label="Testimonials"/>
      <ExternalNavigationLink link="//insidetracker-faq.gorgias.help/" label="Support"/>
    </div>
  );
};

/**
 * Public navigation component
 * @category Header
 * @subcategory Navigation
 * @borrows NavigationPropsTypes as props
 * @component
 * @hideconstructor
 * @example
 * return <PublicNavigation user={null}/>
 */
const PublicNavigation = (props: NavigationPropsTypes) => {
  const { mobile, noFooter } = props;
  return (
    <nav
      className={`${styles.navbar} ${mobile ? styles.navbarMobile : ''} ${noFooter ? styles.navbarMobileNoFooter : ''}`}
    >
      <CommonNavBarList/>
      {!noFooter && contactsList}
      {!noFooter && bottomContactsList}
    </nav>
  );
};

export const MobilePublicNavigation = () => (
  <nav className={`${styles.navbar}  ${styles.navbarMobile}`}>
    <div className={styles.navbarList}>
      <NavigationLink link="/customer/summary" label="Dashboard"/>
    </div>
    <CommonNavBarList/>
    {contactsList}
    {bottomContactsList}
  </nav>
);

export const MobileAdminNavigation = () => (
  <nav className={`${styles.navbar}  ${styles.navbarMobile}`}>
    <div className={styles.navbarList}>
      <NavigationLink
        link="/admin/coach/team-overview"
        label="Dashboard"
        external
        mobile
      />
      <NavigationLink
        link="/admin/coach/users-list"
        label="Teams & Users"
        mobile
      />
      <NavigationLink
        link="/admin/coach/analytics/"
        label="Analytics"
        external
        mobile
      />
      <NavigationLink
        link="/admin/coach/team-nutrition/"
        label="Team Nutrition"
        external
        mobile
      />
      <NavigationLink
        link="/admin/coach/orders-inventory/"
        label="Orders"
        external
        mobile
      />
    </div>
    <div className={styles.contactsList}>
      <NavigationLink external link="/admin/coach/profile" label="Profile"/>
    </div>
    {bottomContactsList}
  </nav>
);

export const CoachNavigation = () => (
  <nav className={styles.navbar}>
    <div className={styles.navbarList}>
      <NavigationLink
        link="/admin/coach/team-overview"
        label="Dashboard"
        external
      />
      <NavigationLink
        link="/admin/coach/users-list"
        label="Teams & Users"
      />
      <NavigationLink
        link="/admin/coach/analytics/"
        label="Analytics"
        external
      />
      <NavigationLink
        link="/admin/coach/team-nutrition/"
        label="Team Nutrition"
        external
      />
      <NavigationLink
        link="/admin/coach/orders-inventory/"
        label="Orders"
        external
      />
      <div style={{ width: '160px', cursor: 'none', display: 'inline-block' }}/>
    </div>
  </nav>
);

export const CustomerNavigation = (props: NavigationPropsTypes) => {
  const { user, mobile } = props;
  const { isDnaOnly, hasAccessToBru } = user;
  const hasAccessToSummary = user.permissions.includes(userPermissions.summary);
  const hasAccessToBloodwork = !isDnaOnly && user.permissions.includes(userPermissions.bloodwork);
  const hasAccessToNutrition = user.permissions.includes(userPermissions.nutrition);
  const hasAccessToAge = user.permissions.includes(userPermissions.age);
  const showInnerAgeLink = hasAccessToAge && (user.allowedToSeeInnerAge || user.hasDiyOrderWithInnerAge);

  return (
    <nav className={`${styles.navbar}  ${mobile ? styles.navbarMobile : ''}`}>
      <div className={styles.navbarList}>
        {hasAccessToSummary
        && <NavigationLink link="/customer/summary/dashboard" label="Dashboard"/>}
        {hasAccessToBloodwork && !isDnaOnly && !mobile
        && (
          <NavigationDropdown link={routes.customer.myResults.main} label="My results">
            <NavigationLink
              dropdownLink
              link={routes.customer.myResults.groups}
              label="Blood results"
            />
            {hasAccessToBru
            && <NavigationLink dropdownLink link="/customer/diy" label="Add blood results"/>}
            <NavigationLink
              dropdownLink
              link="/customer/onboarding/test-list/"
              label="Orders"
            />
          </NavigationDropdown>
        )}
        {hasAccessToBloodwork && !isDnaOnly && mobile && (
          <NavigationCollapse label="My results">
            <NavigationLink link={routes.customer.myResults.groups} label="Blood results"/>
            {hasAccessToBru
            && <NavigationLink link="/customer/diy" label="Add blood results"/>}
            <NavigationLink link="/customer/onboarding/test-list/" label="Orders"/>
          </NavigationCollapse>
        )}
        {!isDnaOnly && !mobile && (
          <NavigationDropdown link="/customer/nutrition" label="Nutrition">
            {hasAccessToNutrition
            && (
              <NavigationLink
                dropdownLink
                link="/customer/nutrition"
                label="Food Recommendations"
              />
            )}
          </NavigationDropdown>
        )}
        {!isDnaOnly && mobile && (
          <NavigationCollapse label="Nutrition">
            {hasAccessToNutrition
            && (
              <NavigationLink
                dropdownLink
                link="/customer/nutrition"
                label="Food Recommendations"
              />
            )}
          </NavigationCollapse>
        )}
        {showInnerAgeLink && <NavigationLink link="/customer/age" label="InnerAge"/>}
        <NavigationLink link="/customer/genetics-report" label="DNA"/>
        {isDnaOnly && <NavigationLink link="/customer/blood-analysis" label="Blood analysis"/>}
        <ExternalNavigationLink link="//insidetracker-faq.gorgias.help//" label="Support"/>
      </div>
      {mobile && (
        <div className={`${styles.navbarList} ${styles.navbarListDivider}`}>
          <NavigationLink link="/customer/settings" label="Profile"/>
          <NavigationLink link="/customer/achievements" label="Achievements"/>
        </div>
      )}
      {contactsList}
      {bottomContactsList}
    </nav>
  );
};

export const AdminNavLinkList = (props: AdminProps) => {
  const { adminMenuLinks } = props;
  const COUNT_LINKS_IN_MAIN_MENU = 6;
  const menuLinks = adminMenuLinks.map((linkData: any[]) => {
    const url = linkData[0];
    const title = linkData[1];

    return (
      <AdminNavigationLink key={title} link={url} label={title} external/>
    );
  });

  let mainMenuLinks: any = menuLinks;
  let subMenuLinks: any = null;

  if (menuLinks.length > COUNT_LINKS_IN_MAIN_MENU) {
    mainMenuLinks = menuLinks.slice(0, COUNT_LINKS_IN_MAIN_MENU - 1);
    subMenuLinks = menuLinks.slice(COUNT_LINKS_IN_MAIN_MENU - 1);
  }

  return (
    <nav className={styles.navbarAdmin}>
      <div className={styles.navbarListAdmin}>
        {mainMenuLinks}
        {subMenuLinks && (
          <AdminNavigationDropdown label="View more" className={styles.adminDropdownList}>
            {subMenuLinks}
          </AdminNavigationDropdown>
        )}
        <AdminNavigationLink key="Logout" link="/admin/login/logout" label="Log Out"/>
      </div>
    </nav>
  );
};

export default PublicNavigation;
